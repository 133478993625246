import React, { useState } from "react";
import Map from "../../../components/Map";
import Marked from "../../../components/Map/Marker";
const RealTimeMap = () => {
    const [viewport, setViewport] = useState({
        latitude: -23.5601802,
        longitude: -46.6415725,
        zoom: 10,
    });
    return (
        <>
            <Map viewport={viewport} setViewport={setViewport} style={{ width: "100%", height: "400px" }}>
                <Marked latitude={-23.5792551} longitude={-46.641581} type="pin" />
            </Map>
        </>
    );
};
export default RealTimeMap;
